export const FETCH_CUSTOMERLIST_INIT_ACTION = "FETCH_CUSTOMERLIST_INIT_ACTION";
export const FETCH_CUSTOMERLIST_SUCCESS_ACTION =
  FETCH_CUSTOMERLIST_INIT_ACTION + "_SUCCESS";
export const FETCH_CUSTOMERLIST_FAIL_ACTION =
  FETCH_CUSTOMERLIST_INIT_ACTION + "_ERROR";

export const CHANGE_STATUS_PROJECT_INIT_ACTION =
  "CHANGE_STATUS_PROJECT_INIT_ACTION";
export const CHANGE_STATUS_PROJECT_SUCCESS_ACTION =
  CHANGE_STATUS_PROJECT_INIT_ACTION + "_SUCCESS";
export const CHANGE_STATUS_PROJECT_FAIL_ACTION =
  CHANGE_STATUS_PROJECT_INIT_ACTION + "_ERROR";

export const FETCH_STATE_INIT_ACTION = "FETCH_STATE_INIT_ACTION";
export const FETCH_STATE_SUCCESS_ACTION = FETCH_STATE_INIT_ACTION + "_SUCCESS";
export const FETCH_STATE_FAIL_ACTION = FETCH_STATE_INIT_ACTION + "_ERROR";

export const FETCH_DISTRICT_INIT_ACTION = "FETCH_DISTRICT_INIT_ACTION";
export const FETCH_DISTRICT_SUCCESS_ACTION =
  FETCH_DISTRICT_INIT_ACTION + "_SUCCESS";
export const FETCH_DISTRICT_FAIL_ACTION = FETCH_DISTRICT_INIT_ACTION + "_ERROR";

export const EXPORT_DATA_INIT_ACTION = "EXPORT_DATA_INIT_ACTION";
export const EXPORT_DATA_SUCCESS_ACTION = EXPORT_DATA_INIT_ACTION + "_SUCCESS";
export const EXPORT_DATA_FAIL_ACTION = EXPORT_DATA_INIT_ACTION + "_ERROR";

export const IMPORT_DATA_INIT_ACTION = "IMPORT_DATA_INIT_ACTION";
export const IMPORT_DATA_SUCCESS_ACTION = IMPORT_DATA_INIT_ACTION + "_SUCCESS";
export const IMPORT_DATA_FAIL_ACTION = IMPORT_DATA_INIT_ACTION + "_ERROR";

export const ADD_PARTY_FILTER_DATA = "ADD_PARTY_FILTER_DATA";

