import * as actionTypes from "./constant";
import { put, takeEvery, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { push } from "connected-react-router";
import { appConfig } from "../../config";

function* commonFunction(value) {
  const userToken = localStorage.getItem("userToken");
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: value.body ? value.body : null,
    });
    
    if (!response.ok) {
      if (response.status === 401) {
        yield put(push("/login"));
      }
      throw response;
    } else {
      if (value.successAction === actionTypes.EXPORT_REPORT_DATA_SUCCESS_ACTION) {
        const contentType = response.headers.get("Content-Type");
        if (contentType && (contentType.includes("application/vnd.ms-excel") || contentType.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") || contentType.includes("text/csv"))) {
          const blobResponse = yield response.blob();
          if (blobResponse instanceof Blob) {
            return blobResponse; 
          } 
        } else {
          if (value.toastMessage) {
            yield toast.success();
          }
        }
      }
      const resJSON = yield response.json();
  
      yield put({
        type: `${value.successAction}`,
        payload: resJSON,
      });
      if (value.successAction) {
        if (value.toastMessage) {
          yield toast.success(value.toastMessage);
        }
      }
      if (value.extraAction) {
        yield put({
          type: `${value.extraAction}`,
          payload: resJSON,
        });
      }
      return resJSON;
    }
  } catch (error) {
    yield put({ type: `${value.failAction}`, error: error });
    if (value.failAction) {
      if (value.toastErrorMessage) {
        yield toast.error(value.toastErrorMessage);
      }
    }
  }
}

function* fetchReport(value) {
  let user = localStorage.getItem("role");
  let url = "";
  if (user === "regionHead") {
    url = "verification/byState?page=1&limit=100000";
  } else {
    url = "verification/all?page=1&limit=100000";
  }
  try {
    let params = {
      api: `${appConfig.ip}/${url}`,
      method: "GET",
      successAction: actionTypes.FETCH_REPORT_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_REPORT_FAIL_ACTION,
    };

    yield call(commonFunction, params);
  } catch (e) {}
}

function* updateCustomerData(value) {
  let customersId = value?.value?.assignItems || [];
  
  let assignedTo = value?.value?.value?.assignTo || "";
    
  let assignedName = value?.value?.value?.assignedName || "";
      
  let type = value?.value?.type || "";

 let toastmsg = '';
  let cust = "";
  let url = "";
  let data = {};

  if (customersId.length === 1) {
    if (type === "Assign") {
      cust = customersId[0];
      toastmsg =  "Successfully assigned";
      url = `/verification/updateCustomer/${cust}`;
      data = {
        assignedTo: assignedTo,
        assignedName: assignedName,
      };
    } else {
      toastmsg =  "Successfully unassigned";
      data= {
        assignedTo:"",
        assignedName:''
      }
      url=`/verification/customers/deallocate/${customersId[0]}`
    }
  } else {
    if (type === "Assign") {
      toastmsg =  "Successfully assigned";
      data = {
        customerIds: customersId,
        assignedTo: assignedTo,
        assignedName: assignedName,
      };
      url = `/verification/updateAllAssignedCustomers`;
    } else {
      toastmsg =  "Successfully unassigned";
      data= {
        customerIds:customersId,
        assignedTo:"",
        assignedName:''
      }
      url='/verification/customers/bulk/deallocate'
    }
  }
  
  try {
    let params = {
      api: `${appConfig.ip}${url}`,
      method: "PATCH",
      successAction: actionTypes.UPDATE_PROJECTS_DATA_SUCCESS_ACTION,
      failAction: actionTypes.UPDATE_PROJECTS_DATA_FAIL_ACTION,
      body: JSON.stringify(data),
      toastMessage: toastmsg,
      toastErrorMessage: "Something went wrong, Try again",
    };

     yield call(commonFunction, params);
   
  } catch (error) {
    // console.error('Error in updateCustomerData:', error);
  }
}

function* fetchCustomerById(value) {
  let id = value.value.id;
  try {
    let params = {
      api: `${appConfig.ip}/verification/customers/${id}`,
      method: "GET",
      successAction: actionTypes.FETCH_CUSTOMERBYID_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_CUSTOMERBYID_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

function* fetchVerification(value) {
  
  let id = value?.value?.id || "";
  let page = value && value.value.page ? value.value.page : 1;
  // let limit = value && value.value.limit ? value.value.limit : 20;
  try {
    let params = {
      api: `${appConfig.ip}/verification/linkedTo/${id}?page=${page}&limit=20`,
      method: "GET",
      successAction: actionTypes.FETCH_VERIFICATION_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_VERIFICATION_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (e) {}
}

function* fetchAllReport(value) {
  let user = localStorage.getItem("role");
  let url = "";
  let toDate= value?.value?.toDate || '';
  let fromDate = value?.value?.fromDate || '';
  let page = value?.value?.page || 1

  if (user === "regionHead") {
    if((toDate !== '' && fromDate!== '')) {
        url = `verification/byState?startDate=${fromDate}&endDate=${toDate}&page=${page}&limit=20`;
    } else {
      // url = `verification/byState?page=${page}&limit=20`;
    }
  } else {
    if((toDate !== '' && fromDate!== '') ) {
        url = `verification/all?startDate=${fromDate}&endDate=${toDate}&page=${page}&limit=20`;
    } else {
    }
  }
  try {
    let params = {
      api: `${appConfig.ip}/${url}`,
      method: "GET",
      successAction: actionTypes.FETCH_ALL_REPORT_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_ALL_REPORT_FAIL_ACTION,
    };

    yield call(commonFunction, params);
   
  } catch (e) {}
}

function* fetchVerifiedReport(value) {
  let user = localStorage.getItem("role");
  let toDate= value?.value?.toDate || '';
  let fromDate = value?.value?.fromDate || '';
  let page = value?.value?.page || 1;

  let url = "";
  if (user === "regionHead") {
    if((toDate !== '' && fromDate!== '')) {
      url = `verification/byState?visitStatus=Verification&startDate=${fromDate}&endDate=${toDate}&page=${page}&limit=20`;
    } else {
      url = `verification/byState?visitStatus=Verification&page=${page}&limit=20`;
    }
  } else {
    if((toDate !== '' && fromDate!== '') ) {
      url = `verification/all?visitStatus=Verification&startDate=${fromDate}&endDate=${toDate}&page=${page}&limit=20`;
    } else {
      url = `verification/all?visitStatus=Verification&page=${page}&limit=20`;
    }
  }

  try {
    let params = {
      api: `${appConfig.ip}/${url}`,
      method: "GET",
      successAction: actionTypes.FETCH_VERIFIED_REPORT_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_VERIFIED_REPORT_FAIL_ACTION,
    };

    yield call(commonFunction, params);
  } catch (e) {}
}

function* fetchCustomerReport(value) {
  
  const role = localStorage.getItem("role");
  let stateItem = value?.value?.stateItem || "";
  let distVal = value?.value?.distVal || "";
  let searchItem = value?.value?.searchItem || "";
  let status = value?.value?.status[0] || "";
  let allocated = value?.value?.allocated[0] || "";
  // let page = value?.value?.page || 1;

  let endPoint = "";
  if (role === "admin") {
    if (stateItem || distVal || searchItem || allocated || status) {

      if(allocated === 'Allocated') {
        endPoint= `/verification/allocated`
      } else if (allocated === 'Unallocated'){
        endPoint =`/verification/nonAllocated`
      } 
      
      if (status === 'Verified') {
        endPoint = `/verification/customersByName?isVerified=true&state=${stateItem}&district=${distVal}&search=${searchItem}`;
      } else if (status === 'New') {
        endPoint = `/verification/customersByName?isVerified=false&state=${stateItem}&district=${distVal}&search=${searchItem}`;
      } else {
        endPoint = `/verification/customersByName?state=${stateItem}&district=${distVal}&search=${searchItem}`;
      }
    } else {
      endPoint = '/verification/customersByName';
    }
  } else if (role === "regionHead") {

    if (stateItem || distVal || searchItem || allocated || status) {
      if(allocated === 'Allocated') {
        endPoint= `/verification/allocationByState`
      } else if (allocated === 'Unallocated'){
        endPoint =`/verification/nonAllocationByState`
      } 
      
      if (status === 'Verified') {
        endPoint = `/verification/getCustomersByState?isVerified=true&state=${stateItem}&district=${distVal}&search=${searchItem}`;
      } else if (status === 'New') {
        endPoint = `/verification/getCustomersByState?isVerified=false&state=${stateItem}&district=${distVal}&search=${searchItem}`;
      } else {
        endPoint = `/verification/getCustomersByState?state=${stateItem}&district=${distVal}&search=${searchItem}`;
      }
    } else {
      endPoint = `/verification/getCustomersByState`;
    }

    // endPoint = `verification/getCustomersByState?page=${page}&limit=20`;
  }

  try {
    let params = {
      api: `${appConfig.ip}${endPoint}`,
      method: "GET",
      failAction: actionTypes.FETCH_CUSTOMER_REPORT_DATA_SUCCESS_ACTION,
      successAction: actionTypes.FETCH_CUSTOMER_REPORT_DATA_FAIL_ACTION,
    };
   let res=  yield call(commonFunction, params);
   
  } catch (error) {
    // console.error('Error fetching customer list', error);
  }
}

function* exportVerification(value) {
  let format = value?.value?.format;  
  let verificationList = value?.value?.data;
  let data = {
    verification : verificationList,
  };
  let urls = `/verification/export?format=${format}`;

  try {
    let params = {
      api: `${appConfig.ip}${urls}`,
      method: "POST",
      successAction: actionTypes.EXPORT_REPORT_DATA_SUCCESS_ACTION,
      failAction: actionTypes.EXPORT_REPORT_DATA_FAIL_ACTION,
      body: JSON.stringify(data),
      toastMessage: "Successfully exported",
      toastErrorMessage: "Failed to export data",
    };
    const blobResponse = yield call(commonFunction, params);
    if (blobResponse instanceof Blob) {
      const url = window.URL.createObjectURL(blobResponse);
      const a = document.createElement("a");
      a.href = url;

      if (format === "excel") {
        a.download = "Verification-Report.xlsx"; 
      } else {
        a.download = "Verification-Report.csv";  
      }

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a); 
      window.URL.revokeObjectURL(url); 
    } else {
    }
  } catch (error) {
    // console.error("Error in exportData:", error);
  }
}

export function* CustomerDetailsWatcher() {
  yield takeEvery(
    actionTypes.FETCH_VERIFICATION_INIT_ACTION,
    fetchVerification
  );
  yield takeEvery(
    actionTypes.UPDATE_PROJECTS_DATA_INIT_ACTION,
    updateCustomerData
  );
  yield takeEvery(
    actionTypes.FETCH_CUSTOMERBYID_INIT_ACTION,
    fetchCustomerById
  );

  yield takeEvery(actionTypes.FETCH_REPORT_INIT_ACTION, fetchReport);
  yield takeEvery(actionTypes.FETCH_ALL_REPORT_INIT_ACTION, fetchAllReport);
  yield takeEvery(
    actionTypes.FETCH_VERIFIED_REPORT_INIT_ACTION,
    fetchVerifiedReport
  );
  yield takeEvery(actionTypes.FETCH_CUSTOMER_REPORT_DATA_INIT_ACTION, fetchCustomerReport)
  yield takeEvery(actionTypes.EXPORT_REPORT_DATA_INIT_ACTION, exportVerification)

}
