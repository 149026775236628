import * as actionTypes from "./constant";
import { put, takeEvery, call } from "redux-saga/effects";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { push } from "connected-react-router";

function* commonFunction(value) {
  const userToken = localStorage.getItem("userToken");
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: value.body ? value.body : null,
    });

    const resJSON = yield response.json();
    if (!response.ok) {
      if (response.status === 401) {
        yield put(push("/login"));
      }
      throw response;
    } else {
      yield put({
        type: `${value.successAction}`,
        payload: resJSON,
      });

      if (value.successAction) {
        if (value.toastMessage) {
          yield toast.success(value.toastMessage);
        }
      }

      if (value.extraAction) {
        yield put({
          type: `${value.extraAction}`,
          payload: resJSON,
        });
      }
    }
    return response;
  } catch (error) {
    yield put({ type: `${value.failAction}`, error: error });
    if (value.failAction) {
      if (value.toastErrorMessage) {
        yield toast.error(value.toastErrorMessage);
      }
    }
  }
}

function* addUser(value) {
  let userName = value?.values?.name;
  let mobileNo= value?.values?.mobileNo;
  let state = value?.values?.state;
  let district = value.values.district ? value.values.district : "";
  let role = value.values.userRole ? value.values.userRole : "fieldUser";
  let pincode = value?.values?.pincode;

  let data = {};
  if(role === 'regionHead') {
    let password =value?.values?.password;
     data = {
      userName: userName,
      mobileNo: mobileNo,
      password: password,
      isActive: true,
      state: state,
      district: district,
      role: role,
      isDeleted: false,
      pincode: pincode,
    };
  } else {
     data = {
      userName: userName,
      mobileNo: mobileNo,
      isActive: true,
      state: state,
      district: district,
      role: role,
      isDeleted: false,
      pincode: pincode,
    };
  }



  try {
    let params = {
      api: `${appConfig.ip}/auth/register`,
      method: "POST",
      successAction: actionTypes.ADD_USER_SUCCESS_ACTION,
      failAction: actionTypes.ADD_USER_FAIL_ACTION,
      extraAction: actionTypes.FETCH_USER_INIT_ACTION,
      body: JSON.stringify(data),
      toastMessage: "User added successfully",
      toastErrorMessage: "Sorry, Mobile Number already exists. Try again",
    };
 yield call(commonFunction, params); 
 
  } catch (err) {}
}

function* fetchUser(value) {
  const role = localStorage.getItem("role");
  let searchVal = value?.value?.searchVal || "";
  let state = value?.value?.state || "";
  let district = value?.value?.district || "";
  let status = value?.value?.status || "";
  let page = (value.value && value.value.page) || 1;
  let pin = value?.value?.pin || '';
  let url = "";
  if (role === "regionHead") {
    if (state || district || searchVal || status || pin) {
      if (status === "Active") {
        url = `users/getUsersByState?state=${state}&district=${district}&isActive=true&page=${page}&limit=20&search=${searchVal}&pincode=${pin}`;
      } else if (status === "Inactive") {
        url = `users/getUsersByState?state=${state}&district=${district}&isActive=false&page=${page}&limit=20&search=${searchVal}&pincode=${pin}`;
      } else {
        url = `users/getUsersByState?state=${state}&district=${district}&page=${page}&limit=20&search=${searchVal}&pincode=${pin}`;
      }
    } else {
      url = `users/getUsersByState?page=${page}&limit=20&`;
    }
  } else {
    if (state || district || searchVal || status || pin) {
      if (status === "Active") {
        url = `users/all?state=${state}&district=${district}&isActive=true&page=${page}&limit=20&search=${searchVal}&pincode=${pin}`;
      } else if (status === "Inactive") {
        url = `users/all?state=${state}&district=${district}&isActive=false&page=${page}&limit=20&search=${searchVal}&pincode=${pin}`;
      } else {
        url = `users/all?state=${state}&district=${district}&page=${page}&limit=20&search=${searchVal}&pincode=${pin}`;
      }
    } else {
      url = `users/all?page=${page}&limit=20`;
    }
  }
  try {
    let params = {
      page: page,
      api: `${appConfig.ip}/${url}`,
      method: "GET",
      successAction: actionTypes.FETCH_USER_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_USER_FAIL_ACTION,
    };
   yield call(commonFunction, params); 
   
  } catch (error) {}
}

function* fetchUserId(value) {
  let id = value;
  try {
    let params = {
      api: `${appConfig.ip}/users/${id}`,
      method: "GET",
      successAction: actionTypes.FETCH_USERID_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_USERID_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

function* fetchFieldUser(value) {
  let role = localStorage.getItem("role");
  let stateFilter = value?.value?.stateFilter || '';
  let districtFilter = value?.value?.districtFilter || '';
  let pin = value?.value?.pin || "";  
  let url = "";
  if (role === "admin") {

    if(stateFilter || districtFilter || pin) {
      url =`users/fieldUsers?state=${stateFilter}&district=${districtFilter}&pincode=${pin}`
    } else {
      url = "users/fieldUsers";
    }
  } else {
    if(stateFilter || districtFilter || pin) {
      url =`users/getUsersByState?&state=${stateFilter}&district=${districtFilter}&pincode=${pin}`
    } else {
      url = "users/getUsersByState";
    }
  }

  try {
    let params = {
      api: `${appConfig.ip}/${url}`,
      method: "GET",
      successAction: actionTypes.FETCH_FIELDUSER_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_FIELDUSER_FAIL_ACTION,
    };
    yield call(commonFunction, params);
   
  } catch (e) {
    // .log("error", e);
  }
}

function* editUser(value) {
  let name =
    value && value.value.value.name
      ? value.value.value.name
      : value.value.item.userName;
  let state =
    value && value.value.value.state
      ? value.value.value.state
      : value.value.item.state;
  let role =
    value && value.value.value.userRole
      ? value.value.value.userRole
      : value.value.item.role;
  let district = "";
  let pin = value?.value?.value?.pincode || "";

  if (role === "fieldUser") {
    district =
      value && value.value.value.district
        ? value.value.value.district
        : value.value.item.district;
  } else {
    district = "";
  }
  let data = {
    userName: name,
    state: state,
    district: district,
    role: role,
    pincode: pin,
  };
  let id = value && value.value.item ? value.value.item._id : "";

  try {
    let params = {
      api: `${appConfig.ip}/users/update/${id}`,
      method: "PUT",
      successAction: actionTypes.EDIT_USER_SUCCESS_ACTION,
      failAction: actionTypes.EDIT_USER_FAIL_ACTION,
      body: JSON.stringify(data),
      toastMessage: "User details updated successfully",
      toastErrorMessage: "Something went wrong. Try again",
    };

    yield call(commonFunction, params);
  } catch (error) {}
}

function* changeStatus(value) {
  let id = value?.value?._id;
   
  let data = {
    isActive: value.value.isActive === true ? false : true,
  };
  try {
    let params = {
      api: `${appConfig.ip}/users/update/${id}`,
      method: "PUT",
      successAction: actionTypes.CHANGE_STATUS_USER_SUCCESS_ACTION,
      failAction: actionTypes.CHANGE_STATUS_USER_FAIL_ACTION,
      body: JSON.stringify(data),
      toastMessage: "User successfully updated ",
      toastErrorMessage: "Something went wrong. Try again",
    };
    yield call(commonFunction, params);

  } catch (err) {}
}

function* exportUserData(value) {
  try {
    let params = {
      api: `${appConfig.ip}/`,
      method: "PUT",
      successAction: actionTypes.EXPORT_USER_DATA_SUCCESS_ACTION,
      failAction: actionTypes.EXPORT_USER_DATA_FAIL_ACTION,
      toastMessage: "User details exported successfully",
      toastErrorMessage: "Something went wrong. Try again",
    };
    yield call(commonFunction, params);
  } catch (err) {}
}

export function* UserActionWatcher() {
  yield takeEvery(actionTypes.ADD_USER_INIT_ACTION, addUser);
  yield takeEvery(actionTypes.FETCH_FIELDUSER_INIT_ACTION, fetchFieldUser);
  yield takeEvery(actionTypes.FETCH_USER_INIT_ACTION, fetchUser);
  yield takeEvery(actionTypes.EDIT_USER_INIT_ACTION, editUser);
  yield takeEvery(actionTypes.CHANGE_STATUS_USER_INIT_ACTION, changeStatus);
  yield takeEvery(actionTypes.EXPORT_USER_DATA_INIT_ACTION, exportUserData);
}
