import { generatePath } from "react-router-dom/cjs/react-router-dom";
import * as actionTypes from "./constant";
import { act } from "react-test-renderer";

const initialState = {
  processing: false,
  error: false,
  emissionFactorByNoList: [],
  generalInfoList: {},
  verificationList: [],
  verificationCount: 0,
  reportData: [],
  reportCount: 0,
  activityFetchId:'',
  allReportData:[],
  allReportCount:0,
  verifiedReportData:[],
  verifiedReportCount:0,
  customerReportData:[]
};

export default function CustomerDetailsReducer(state = initialState, action) {
  switch (action.type) {
    // case actionTypes.ADD_PROJECT_DATA_INIT_ACTION:
    //   return {
    //     ...state,
    //     generalInfoList: action.value,
    //   };
    case actionTypes.FETCH_REPORT_SUCCESS_ACTION:
      return {
        ...state,
        reportData: action.payload.verification,
        reportCount: action.payload.totalCount,
      };
    case actionTypes.FETCH_VERIFICATION_SUCCESS_ACTION:   
      return {
        ...state,
        verificationCount: action.payload.totalCount,
        verificationList: action.payload.details,
      };
      case actionTypes.ADD_ACTIVITYID_SUCCESS_ACTION:           
      return {
        ...state,
        activityFetchId: action.value.id,
      };
    case actionTypes.FETCH_CUSTOMERBYID_SUCCESS_ACTION:
      return {
        ...state,
        generalInfoList: {...action.payload},
      };
    case actionTypes.FETCH_ALL_REPORT_SUCCESS_ACTION:
    return {
      ...state,
      allReportData: action.payload.verification,
      allReportCount: action.payload.totalCount,    
    };
    case actionTypes.FETCH_VERIFIED_REPORT_SUCCESS_ACTION:
    return {
      ...state,
      verifiedReportData: action.payload.verification,
      verifiedReportCount: action.payload.totalCount,
    };
    case actionTypes.FETCH_CUSTOMER_REPORT_DATA_SUCCESS_ACTION:
      
      return {
        ...state,
        customerReportData : action.payload.customers,
      };

    default:
      return {
        ...state,
      };
  }
}
