import * as actionTypes from "./constant";

const initialState = {
  processing: false,
  customerList: [],
  customerCount: 0,
  error: false,
  stateList:[],
  stateCount:0,
  districtList:[],
  districtCount:0,
  partyFilterData: {
    state: '',
    district: '',
    status: '',
    allocation: '',
    assignTo: '',
    pincode: '',
    searchItem: '',
  },
};
export default function MyCasesReducer(state = initialState, action) {
  switch (action.type) {

    case actionTypes.FETCH_CUSTOMERLIST_SUCCESS_ACTION:
      
      return {
        ...state,
        customerList: action.payload.customers
          ? action.payload.customers
          : action.payload.assignedCustomers,

        customerCount: action.payload.totalCount,
      };

    case actionTypes.CHANGE_STATUS_PROJECT_SUCCESS_ACTION:
      return {
        ...state,
        customerList: state.customerList.map((list) =>
          list.id === action.payload.id ? action.payload : list
        ),
        customerCount: state.customerCount,
      };
      case actionTypes.FETCH_STATE_SUCCESS_ACTION:        
        return {
          ...state,
          stateList: action.payload.state,
          stateCount: action.payload.totalCount,
        };
      case actionTypes.FETCH_DISTRICT_SUCCESS_ACTION:
        return {
          ...state,
          districtList: action.payload.district,
          districtCount: action.payload.totalCount,
        };
      case  actionTypes.ADD_PARTY_FILTER_DATA:
        return {
          ...state.partyFilterData,
           ...action.payload
        }
  

    default:
      return {
        ...state,
      };
  }
}
