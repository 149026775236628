import { call, all } from "redux-saga/effects";

import { LoginActionWatcher } from "../containers/LoginContainer/saga";
import { dashboardActionWatcher } from "../containers/DefaultLayout/saga";
import { CustomerDetailsWatcher } from "../containers/CustomerViewContainer/saga";
import { MyCasesActionWatcher } from "../containers/MyCasesContainer/saga";
import { UserActionWatcher } from "../containers/UserContainer/saga";
import { CustomerBulkAssign } from "../containers/CustomerBulkAssign/saga";

function* rootSaga() {
  yield all([
    call(LoginActionWatcher),
    call(dashboardActionWatcher),
    call(CustomerDetailsWatcher),
    call(UserActionWatcher),
    call(MyCasesActionWatcher),
    call(CustomerBulkAssign)
  ]);
}

export default rootSaga;
