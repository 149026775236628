export const navLicensee = {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "fa fa-th-large",
    },

    {
      name: "My Parties",
      url: "/my parties",
      icon: "fa fa-file-text",
    },
    {
      name: "Users",
      url: "/users",
      icon: "fa fa-users",
    },
    {
      name: "Bulk Assign",
      url: "/Bulk Assign",
      icon: 'fa fa-clipboard'
    },
    {
      // url: "/report",
      name: "Report",
      icon: "fa fa-clipboard",
         children: [
          {
            name:  "All Activities",
            url:"/All Activities",
          },
        {
          name: "Verification Report",
          url: "/Verification Report",
        },
     
        // {
        //   name: "Emission Factor Sub Section",
        //   url: "/masterdata/EFSession2",
        // },

        // {
        //   name: "Emission Factor",
        //   url: "/masterdata/EmissionFactorSession",
        // },
      ],
    },
  ],
};
