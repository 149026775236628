import * as actionTypes from "./constant";
const addgeneralinformation = (value) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_PROJECT_DATA_INIT_ACTION,
      value: value,
    });
  };
};

const fetchReport = (value) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_REPORT_INIT_ACTION,
      value: value,
    });
  };
};

const updateCustomerData = (value) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_PROJECTS_DATA_INIT_ACTION,
      value: value,
    });
  };
};
const fetchCustomerById = (value) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_CUSTOMERBYID_INIT_ACTION,
      value: value,
    });
  };
};

const fetchVerification = (value) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_VERIFICATION_INIT_ACTION,
      value: value,
    });
  };
};

const addActivityFetchId = (value) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_ACTIVITYID_SUCCESS_ACTION,
      value: value,
    });
  };
};

const fetchAllReport = (value) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_ALL_REPORT_INIT_ACTION,
      value: value,
    });
  };
};

const fetchVerifiedReport = (value) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_VERIFIED_REPORT_INIT_ACTION,
      value: value,
    });
  };
};
const exportVerification = (value) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EXPORT_REPORT_DATA_INIT_ACTION,
      value: value,
    });
  };
};
const fetchCustomerReport = (value) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_CUSTOMER_REPORT_DATA_INIT_ACTION,
      value: value,
    });
  };
};

export default {
  addgeneralinformation,
  updateCustomerData,
  fetchCustomerById,
  fetchVerification,
  fetchReport,
  addActivityFetchId,
  fetchVerifiedReport,
  fetchAllReport,
  exportVerification,
  fetchCustomerReport
};
