import React, { Component } from "react";
import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {
  
  render() {
    const currentDate = new Date();  
    const year = currentDate.getFullYear();  
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <div className="footerHeight"> 
        <span style={{textAlign:"right", marginRight:"50px"}}>
          {process.env.REACT_APP_VERSION}
        </span>
        </div>
      </React.Fragment>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;


// import React from 'react';
// import { useTheme } from '@mui/material/styles';
// import styles from './style';
// import { Box, Typography } from '@mui/material';
// const Footer = () => {  const theme = useTheme(); 
//    const currentDate = new Date(); 
//     const year = currentDate.getFullYear(); 
//      const style = styles(theme);  
//      return (   
//        <Box>      
//         <Box sx={style.footerboxMain}>       
//            <Box sx={style.footerbox}>          
//             <Typography sx={style.FooterTitle} style={{ marginTop: '35px' }}>          
//               Copyright © {year} by Transys Global Forwarding Private Ltd. All Rights Reserved. {process.env.REACT_APP_VERSION}               
//               </Typography>        
//               </Box>     
//                </Box>   
//                 </Box>  
//                 );};export default Footer;