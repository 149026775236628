import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router";
import * as History from "history";

import createRootReducer from "../reducers";
import saga from "./sagas";

const sagaMiddleware = createSagaMiddleware();
export const history = History.createHashHistory();

const middleware = [thunk, sagaMiddleware, routerMiddleware(history)];

const initialState = {};

export default function configureStore(onCompletion: () => void): any {
  const enhancer = compose(applyMiddleware(...middleware));
  const store = createStore(createRootReducer(history), initialState, enhancer);
  sagaMiddleware.run(saga);

  if (typeof onCompletion === "function") {
    onCompletion();
  }

  return { store, history };
}
